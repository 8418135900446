<template>
  <div class="episode" :ref="`podcastEpisode_${_uid}`">
    <div
      class="episode__title"
      :id="`podcastEpisode-${ind + 1}`"
      :style="`color: ${color}`"
      v-html="episode.name"
    ></div>
    <div class="episode__description" v-html="episode.description"></div>
    <video
      controls
      :src="$root.host + episode.file.url"
      v-if="episode.file.url && !isAudio"
      :poster="episode.preview"
    ></video>
    <audio
      controls
      :src="$root.host + episode.file.url"
      v-if="episode.file.url && isAudio"
    ></audio>
    <div
      class="episode__bottom-tip"
      v-if="
        episode.approval_number &&
        episode.approval_date &&
        episode.approval_date_end
      "
    >
      {{
        `Номер одобрения: ${episode.approval_number} Дата одобрения: ${this.$m(
          episode.approval_date
        ).format("DD.MM.YYYY")}. Дата истечения: ${this.$m(
          episode.approval_date_end
        ).format("DD.MM.YYYY")}`
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PodcastEpisode",
  props: {
    episode: Object,
    color: String,
    ind: Number,
  },
  data: () => ({
    openInner: false,
    timing: null,
    timingTarget: 60,
  }),
  computed: {
    isAudio() {
      let audio = this.episode.file.url;
      const extension = audio.match(/\.([^.]+)$|$/)[1].toLowerCase();
      const audioTypes = ["vac", "mp3"];
      return audioTypes.includes(extension);
    },
  },
  methods: {
    toggleArrow() {
      this.openInner = !this.openInner;
    },
     cyclicAudio(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "audio 30 sec", {
            "audio 30 sec": {
              "audio name": vm.episode.name,
              "audio duration": duration,
               ...vm.$root.ymFields
            },
          });
        }
        vm.cyclicAudio();
      }, 30000);
    },
     cyclicVideo(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video 30 sec", {
            "video 30 sec": {
              "video name": vm.episode.name,
              "video duration": duration,
               ...this.$root.ymFields
            },
          });
        }
        vm.cyclicVideo();
      }, 30000);
    },
  },
  async mounted() {
    const vm = this;
    if (this.ind === 0) {
      this.openInner = true;
    }
    if (this.$refs[`podcastEpisode_${this._uid}`]) {
      const video =
        this.$refs[`podcastEpisode_${this._uid}`].querySelector("video");
      if (video) {
        // if(this.$route.query.t) {
        //   video.currentTime = this.$route.query.t
        // }
        video.addEventListener("play", function () {
          if (this.currentTime === 0) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video start", {
                "video start": {
                  "video name": vm.episode.name,
                  "video duration": Math.round(this.duration),
                   ...vm.$root.ymFields
                },
              });
            }
          } else {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video resume", {
                "video resume": {
                  "video name": vm.episode.name,
                  "video duration": Math.round(this.duration),
                  ...vm.$root.ymFields
                },
              });
            }
          }
          vm.cyclicVideo(Math.round(this.duration));
        });
        video.addEventListener("pause", function () {
          if (this.currentTime !== this.duration) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video pause", {
                "video pause": {
                  "video name": vm.episode.name,
                  "video duration": Math.round(this.duration),
                   ...vm.$root.ymFields
                },
              });
            }
          }
          clearTimeout(vm.timer);
          vm.timer = null;
        });
        video.addEventListener("ended", function () {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video end", {
              "video end": {
                "video name": vm.episode.name,
                "video duration": Math.round(this.duration),
                 ...vm.$root.ymFields
              },
            });
          }
        });
        video.addEventListener("timeupdate", function () {
          let percent = Math.ceil((this.currentTime / this.duration) * 100);
          if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video percentage watched", {
                "video percentage watched": {
                  "video percentage watched": percent,
                   ...vm.$root.ymFields
                },
              });
            }
            this.percent = percent;
          }
          if (
            vm.timing === null &&
            Math.floor(this.currentTime) >= vm.timingTarget
          ) {
            vm.$emit("timingCompleted", vm.ind + 1, vm.timingTarget);
            vm.timing = Math.floor(this.currentTime);
          }
        });
      }

      if (
        this.$route.hash.includes("podcastEpisode") &&
        document.querySelector(this.$route.hash)
      ) {
        //  document.querySelector(this.$route.hash).scrollIntoView({ behavior: "smooth" });
        let y = document.querySelector(this.$route.hash).offsetTop;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }

     if (this.$refs[`podcastEpisode_${this._uid}`]) {
        const audio =
          this.$refs[`podcastEpisode_${this._uid}`].querySelector("audio");
        if (audio) {
          audio.addEventListener("play", function () {
            if (this.currentTime === 0) {
              if (typeof ym !== "undefined") {
                ym(91468266, "reachGoal", "audio start", {
                  "audio start": {
                    "audio name": vm.episode.name,
                    "audio duration": Math.round(this.duration),
                    ...vm.$root.ymFields,
                  },
                });
              }
            } else {
              if (typeof ym !== "undefined") {
                ym(91468266, "reachGoal", "audio resume", {
                  "audio resume": {
                    "audio name": vm.episode.name,
                    "audio duration": Math.round(this.duration),
                    ...vm.$root.ymFields,
                  },
                });
              }
            }
            vm.cyclicAudio(Math.round(this.duration));
          });
          audio.addEventListener("pause", function () {
            if (this.currentTime !== this.duration) {
              if (typeof ym !== "undefined") {
                ym(91468266, "reachGoal", "audio pause", {
                  "audio pause": {
                    "audio name": vm.episode.name,
                    "audio duration": Math.round(this.duration),
                    ...vm.$root.ymFields,
                  },
                });
              }
            }
            clearTimeout(vm.timer);
            vm.timer = null;
          });
          audio.addEventListener("ended", function () {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "audio end", {
                "audio end": {
                  "audio name": vm.episode.name,
                  "audio duration": Math.round(this.duration),
                  ...vm.$root.ymFields,
                },
              });
            }
          });
          audio.addEventListener("timeupdate", function () {
            let percent = Math.ceil((this.currentTime / this.duration) * 100);
            if (
              this.percent !== percent &&
              percent !== 0 &&
              percent % 5 === 0
            ) {
              if (typeof ym !== "undefined") {
                ym(91468266, "reachGoal", "audio percentage listened", {
                  "audio percentage watched": {
                    "audio percentage watched": percent,
                    ...vm.$root.ymFields,
                  },
                });
              }
              this.percent = percent;
            }
          });
        }
      }
  },
};
</script>

<style lang="scss" scoped>
.episode {
  margin-bottom: 24px;

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #d0006f;
    margin-bottom: 24px;
  }

  &__description {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &__speakers {
    padding: 24px;
    background-color: rgba(222, 130, 60, 0.1);
    border: 1px solid #de823c;

    &-title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #1f1f1f;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 52px;
      row-gap: 32px;

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        column-gap: 0;
      }
    }
  }

  &__speaker {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &-image-container {
      width: 66px;
      height: 66px;
      flex-shrink: 0;
      margin-right: 12px;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-name {
      margin-bottom: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #3c4242;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8b8e8e;
    }
  }

  &__bottom-tip {
    margin-top: 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  audio {
    width: 100%;
  }
}
</style>